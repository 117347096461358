import React from "react";
import "./Qoutes.scss"
import SideBarQoutes from "./SideBarQoutes";

export default function QoutesPage(props) {
  return (
    <div className="container px-5 py-6 mx-auto">
    <article className="blogPost">
  
        {props.children}
    </article>
  </div>
  )
}
