import React from "react";
import "./Qoutes.scss"

export default function Qoute({text,number,author}) {
  return (
    <>
      {text && (
          <div className="qoute-element">
            <div className="qouteNumber">
              {number}.
            </div>
            <div className="qouteText">
            {text} 
            <div>
             <b>- {author}</b>
              </div>
            </div>
          </div>
      )}
    </>
  );
}
